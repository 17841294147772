<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="pf-card">
    <br />
    <div class="pf-search-grid-1">
      <!-- Sender -->
      <div class="row row1 col2">
        <label
          for="senderInput"
          class="pf-search-input-label"
          v-html="$gettext('suche_sender')"
        />
        <div>
          <img class="pf-search-icon" :src="pfSearchSvg" alt="" />
          <input
            id="senderInput"
            v-model="findInput('senderInput').value"
            style="padding-left: 2.5em"
            class="pf-search-input"
            type="text"
            @keyup.enter="getExtendedSearchVue()"
          />
          <BaseTooltip :content="$gettext('suche_tooltip_sender')" />
        </div>
      </div>

      <div id="pf-collapse-button" class="row4 col1">
        <button
          class="row4 col1 collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
          style="
            float: right;
            height: min-content;
            width: max-content;
            padding: 50px 20px 30px 20px;
          "
        >
          <span
            id="pf-collapse-mehr"
            v-html="$gettext('suche_collapse_mehr')"
          />
          <span
            id="pf-collapse-weniger"
            v-html="$gettext('suche_collapse_weniger')"
          />
        </button>
      </div>

      <!-- Empfänger -->
      <div class="row row1 col3">
        <label
          for="recipientInput"
          class="pf-search-input-label"
          v-html="$gettext('suche_empfaenger')"
        />
        <div>
          <img class="pf-search-icon" :src="pfSearchSvg" alt="" />
          <input
            id="recipientInput"
            v-model="findInput('recipientInput').value"
            style="padding-left: 2.5em"
            class="pf-search-input"
            type="text"
            @keyup.enter="getExtendedSearchVue()"
          />
          <BaseTooltip :content="$gettext('suche_tooltip_empfaenger')" />
        </div>
      </div>

      <!-- Titel/Belegnummer. -->
      <div class="row row2 col2">
        <label
          for="documentNr"
          class="pf-search-input-label"
          v-html="$gettext('resultate_felder_documentNr')"
        />
        <div>
          <img class="pf-search-icon" :src="pfSearchSvg" alt="" />
          <input
            id="documentNr"
            v-model="findInput('documentNr').value"
            style="padding-left: 2.5em"
            class="pf-search-input"
            type="text"
            @keyup.enter="getExtendedSearchVue()"
          />
          <BaseTooltip :content="$gettext('suche_tooltip_belegnummer')" />
        </div>
      </div>

      <!-- Währung -->
      <div class="row row2 col3">
        <label
          for="documentCurrency"
          class="pf-search-input-label"
          v-html="$gettext('resultate_felder_currency')"
        />
        <div>
          <select
            id="documentCurrency"
            v-model="findInput('documentCurrency').value"
            style="padding-top: 17px"
            name="type"
            class="pf-search-input"
          >
            <option value="0" />
            <template
              v-for="(value, key) in currencies"
              :key="'currencies' + key"
            >
              <option :value="key">
                {{ key }}
              </option>
            </template>
          </select>
        </div>
      </div>

      <!-- Belegdatum von -->
      <div class="row row3 col2">
        <label
          for="documentDateStart"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_belegdatum_beginn')"
        />
        <div>
          <input
            id="documentDateStart"
            v-model="findInput('documentDateStart').value"
            class="pf-search-input-date"
            type="date"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Belegdatum bis -->
      <div class="row row3 col3">
        <label
          for="documentDateEnd"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_belegdatum_ende')"
        />

        <div>
          <input
            id="documentDateEnd"
            v-model="findInput('documentDateEnd').value"
            class="pf-search-input-date"
            type="date"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Bruttobetrag von -->
      <div class="row row4 col2">
        <label
          for="documentGrossAmountStart"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_grossamount_beginn')"
        />
        <div>
          <input
            id="documentGrossAmountStart"
            v-model="findInput('documentGrossAmountStart').value"
            class="pf-search-input"
            type="number"
            onkeydown="return event.keyCode !== 69"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Bruttobetrag bis -->
      <div class="row row4 col3">
        <label
          for="documentGrossAmountEnd"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_grossamount_ende')"
        />
        <div>
          <input
            id="documentGrossAmountEnd"
            v-model="findInput('documentGrossAmountEnd').value"
            class="pf-search-input"
            type="number"
            onkeydown="return event.keyCode !== 69"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>
      <!-- Ende pf-search-grid-1 -->
    </div>

    <!-- Beginn Kollaps -->
    <div id="collapseExample" class="pf-search-grid-2 collapse">
      <!-- Beginn Fälligkeit -->
      <div class="row row1 col2">
        <label
          for="documentDueDateStart"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_faelligkeit_beginn')"
        />
        <div>
          <input
            id="documentDueDateStart"
            v-model="findInput('documentDueDateStart').value"
            class="pf-search-input-date"
            type="date"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Ende Fälligkeit -->
      <div class="row row1 col3">
        <label
          for="documentDueDateEnd"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_faelligkeit_ende')"
        />
        <div>
          <input
            id="documentDueDateEnd"
            v-model="findInput('documentDueDateEnd').value"
            class="pf-search-input-date"
            type="date"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Dateiname -->
      <div class="row row2 col2">
        <label
          for="fileName"
          class="pf-search-input-label"
          v-html="$gettext('resultate_felder_fileName')"
        />
        <div>
          <img class="pf-search-icon" :src="pfSearchSvg" alt="" />
          <input
            id="fileName"
            v-model="findInput('fileName').value"
            style="padding-left: 2.5em"
            class="pf-search-input"
            type="text"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Dokumenten-Typ -->
      <div class="row row3 col2">
        <label
          for="type"
          class="pf-search-input-label"
          v-html="$gettext('resultate_felder_documentType')"
        />
        <div>
          <select
            id="documentTypeId"
            v-model="findInput('documentType').value"
            style="padding-top: 17px"
            name="type"
            class="pf-search-input"
          >
            <option value="0" />
            <template v-for="(value, key) in documenttypes" :key="key">
              <option v-if="value !== ''" :value="key">
                {{ value }}
              </option>
            </template>
          </select>
        </div>
      </div>

      <!-- Endempfänger -->
      <div class="row row3 col3">
        <label
          for="finalRecipientInput"
          class="pf-search-input-label"
          v-html="$gettext('suche_endempfaenger')"
        />
        <div>
          <img class="pf-search-icon" :src="pfSearchSvg" alt="" />
          <input
            id="finalRecipientInput"
            v-model="findInput('finalRecipientInput').value"
            style="padding-left: 2.5em"
            class="pf-search-input"
            type="text"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Nettobetrag von -->
      <div class="row row4 col2">
        <label
          for="documentNetAmountStart"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_netamount_beginn')"
        />
        <div>
          <input
            id="documentNetAmountStart"
            v-model="findInput('documentNetAmountStart').value"
            class="pf-search-input"
            type="number"
            onkeydown="return event.keyCode !== 69"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>

      <!-- Nettobetrag bis -->
      <div class="row row4 col3">
        <label
          for="documentNetAmountEnd"
          class="pf-search-input-label"
          v-html="$gettext('suche_erweitert_netamount_ende')"
        />
        <div>
          <input
            id="documentNetAmountEnd"
            v-model="findInput('documentNetAmountEnd').value"
            class="pf-search-input"
            type="number"
            onkeydown="return event.keyCode !== 69"
            @keyup.enter="getExtendedSearchVue()"
          />
        </div>
      </div>
      <!-- Ende Kollaps -->
    </div>

    <div class="button-on-grid">
      <button
        id="suchknopf"
        class="btn btn-primary row1 col3"
        style="justify-self: end; margin-right: calc(500px - 413px)"
        @click="getExtendedSearchVue()"
        v-html="
          $ngettext(
            'suche_erweitert_suchen_singular',
            'suche_erweitert_suchen_plural',
            2
          )
        "
      />
    </div>
    <br />

    <!-- Search-results -->
    <div
      v-if="resultsVisible"
      id="searchResultsTutti"
      v-loading="searchResultsLoading"
      :style="{ padding: '0px 40px' }"
    >
      <br />
      <br />
      <div
        v-if="moreThanMax === false"
        class="pf-lead"
        v-html="
          sprintf(
            $ngettext(
              'include_results_gefunden_singular',
              'include_results_gefunden_plural',
              searchResults.length
            ),
            searchResults.length
          )
        "
      />
      <div
        v-if="moreThanMax"
        class="pf-lead"
        v-html="
          sprintf(
            $gettext('include_results_gefunden_tausend'),
            numberOfDocs,
            max
          )
        "
      />
      <br />

      <div
        v-if="searchResults.length > 0"
        :style="{ marginBottom: '10px' }"
        v-html="$gettext('suche_toggles_spalten')"
      />
      <div v-if="searchResults.length > 0">
        <template
          v-for="(field, index) in inputFieldsForTable"
          :key="index + field"
        >
          <!--  eslint-disable-next-line prettier/prettier-->
          <SucheFormularToggle :show-toggle="field.showToggle" :label="field.label" @toggle="field.showToggle = !field.showToggle; tableKey += 1;"
          />
        </template>
      </div>
      <br />
      <div v-if="searchResults.length > 1" :style="{ marginBottom: '10px' }">
        <div v-html="textResultOrder" />
      </div>

      <SucheFormularResultate
        v-if="searchResults.length > 0"
        :key="tableKey"
        v-loading="searchResultsLoading"
        :input-fields="columnsForTable"
        :search-results="searchResults"
        @order-new="setOrder"
      />
    </div>
  </div>
</template>

<script>
import BaseTooltip from './BaseTooltip.vue'
import SucheFormularResultate from './SucheFormularResultate.vue'
import currencies from '../assets/currencies.json'
import { getCurrentInstance } from 'vue'
import pfSearchSvg from '../assets/pf-icons/pf-search.svg'
import SucheFormularToggle from './SucheFormularToggle.vue'

class Input {
  constructor(
    prop,
    inputName = '',
    label = '',
    isInput = true,
    isField = true,
    value = '',
    width = null,
    order = 'asc',
    ordered = false,
    showToggle = true
  ) {
    this.prop = prop
    this.inputName = inputName
    this.label =
      getCurrentInstance().appContext.config.globalProperties.$gettext(label)
    this.isInput = isInput
    this.isField = isField
    this.value = value
    this.width = width
    this.order = order
    this.ordered = ordered
    this.showToggle = showToggle
  }
}

export default {
  name: 'SucheFormular',

  components: {
    BaseTooltip,
    SucheFormularResultate,
    SucheFormularToggle,
  },

  props: {
    spracheLang: {
      type: String,
      default: 'de_CH',
    },
  },

  data() {
    return {
      tableWidth: '100',
      tableKey: 0,
      showFinalRecipientName: false,
      pfSearchSvg: pfSearchSvg,
      searchResultsLoading: false,
      resultsVisible: false,
      moreThanMax: null,
      max: 1000,
      numberOfDocs: null,
      currencies: currencies,
      documenttypes: [],
      searchResults: [],
      inputFields: [
        new Input(
          'fileName',
          'fileName',
          'resultate_felder_fileName',
          undefined,
          undefined,
          undefined,
          400
        ),
        /**
         * Sender input-field is used to search in the next three fields.
         */
        new Input(
          'documentSenderName',
          'senderInput',
          'resultate_felder_documentSenderName',
          undefined,
          undefined,
          undefined,
          200
        ), // Sender input is saved here.
        new Input(
          'documentSenderParticipantId',
          '',
          'resultate_felder_billerId',
          false,
          undefined,
          undefined,
          143
        ),
        new Input(
          'documentSenderCustomerId',
          '',
          'resultate_felder_senderCustomerId',
          false,
          undefined,
          undefined,
          143
        ),
        /**
         * Recipient input field is used to search in documentRecipientName, documentRecipientParticipantId and documentRecipientCustomerId
         */
        new Input(
          'documentRecipientName',
          'recipientInput',
          'resultate_felder_documentRecipientName',
          undefined,
          undefined,
          undefined,
          200
        ), // Recipient input is saved her.
        new Input(
          'documentFinalRecipientName',
          'finalRecipientInput',
          'resultate_felder_documentFinalRecipientName',
          undefined,
          undefined,
          undefined,
          110,
          undefined,
          undefined,
          false
        ),
        new Input(
          'documentRecipientParticipantId',
          '',
          'resultate_felder_documentRecipientParticipantId',
          false,
          undefined,
          undefined,
          143
        ), // Searched for with recipient input
        new Input(
          'documentRecipientCustomerId',
          '',
          'resultate_felder_documentRecipientCustomerId',
          false,
          undefined,
          undefined,
          148
        ), // Searched for with recipient input
        new Input(
          'documentType',
          'documentType',
          'resultate_felder_documentType',
          undefined,
          undefined,
          undefined,
          120,
          undefined,
          undefined,
          false
        ),
        new Input(
          'documentNr',
          'documentNr',
          'resultate_felder_documentNr',
          undefined,
          undefined,
          undefined,
          134
        ),
        /**
         * Value of input documentDateStart
         */
        new Input(
          'documentDate',
          'documentDateStart',
          'resultate_felder_documentDate',
          undefined,
          undefined,
          undefined,
          90
        ),
        new Input(
          'documentDateEnd',
          'documentDateEnd',
          'resultate_felder_documentDate',
          true,
          false
        ),
        /**
         * Value of input documentDueDateStart
         */
        new Input(
          'documentDueDate',
          'documentDueDateStart',
          'resultate_felder_documentDueDate',
          undefined,
          undefined,
          undefined,
          130,
          undefined,
          true,
          false
        ),
        new Input(
          'documentDueDateEnd',
          'documentDueDateEnd',
          'resultate_felder_documentDueDate',
          true,
          false
        ),
        new Input(
          'documentCurrency',
          'documentCurrency',
          'resultate_felder_currency',
          undefined,
          undefined,
          undefined,
          80
        ),
        /**
         * Value of input documentGrossAmountStart
         */
        new Input(
          'documentGrossAmount',
          'documentGrossAmountStart',
          'resultate_felder_documentGrossAmount',
          undefined,
          undefined,
          undefined,
          95
        ),
        new Input(
          'documentGrossAmountEnd',
          'documentGrossAmountEnd',
          'resultate_felder_documentGrossAmount',
          true,
          false
        ),
        /**
         * Value of input documentNetAmountStart
         */
        new Input(
          'documentNetAmount',
          'documentNetAmountStart',
          'resultate_felder_documentNetAmount',
          undefined,
          undefined,
          undefined,
          95,
          undefined,
          undefined,
          false
        ),
        new Input(
          'documentNetAmountEnd',
          'documentNetAmountEnd',
          'resultate_felder_documentNetAmount',
          true,
          false
        ),
      ],
    }
  },

  computed: {
    textResultOrder() {
      return this.sprintf(
        this.$gettext('include_results_sort'),
        '<img class= "pf-icon-small" src="/images/pf-sort-down-fill-black.svg"  alt="">',
        '<img class="pf-icon-small" src="/images/pf-sort-up.svg"  alt="">'
      )
    },
    inputs() {
      const filteredInputs = this.inputFields.filter((_) => {
        return _.isInput && _.value !== ''
      })
      const inputsObj = {}
      filteredInputs.forEach((_) => {
        inputsObj[_.inputName] = _.value
      })
      return inputsObj
    },

    inputFieldsForTable() {
      return this.inputFields.filter((_) => _.isField)
    },
    columnsForTable() {
      return this.inputFieldsForTable.filter((_) => _.showToggle)
    },
  },
  mounted() {
    /**
     * Set locale for vue3-gettext.
     */
    this.$language.current = this.spracheLang
    this.axios(this.axiosConfig('/get_documenttypes.php')).then((response) => {
      this.documenttypes = response.data
    })
  },

  methods: {
    calcTableWidth() {
      let width = 0
      for (const col of this.inputFieldsForTable) {
        if (col.showToggle) {
          width += col.width
        }
      }
      this.tableWidth = width
      console.log(this.tableWidth)
    },
    setOrder(prop, order) {
      this.inputFields.forEach((_) => (_.ordered = false))
      const input = this.inputFields.find((_) => _.prop === prop)
      input.ordered = true
      input.order = order
      this.getExtendedSearchVue(prop, order)
    },
    axiosConfig(url, method = 'get', data = {}) {
      return {
        url,
        method,
        baseURL: import.meta.env.VITE_BASE_URL_AXIOS,
        data,
      }
    },
    findInput(input) {
      return this.inputFields.find((el) => el.inputName === input)
    },
    getExtendedSearchVue(sortField = 'documentDueDate', order = 'asc') {
      this.resultsVisible = true
      this.searchResultsLoading = true
      const inputs = {
        inputs: this.inputs,
        sortField,
        order,
      }
      this.axios(
        this.axiosConfig('/get_extended_search_dossiers.php', 'post', inputs)
      ).then((response) => {
        const data = response.data
        this.searchResults = data.searchResults
        this.moreThanMax = data.moreThanMax
        this.max = data.max
        this.numberOfDocs = data.numberOfDocs
        this.resultsVisible = true
        this.searchResultsLoading = false
      })
    },
  },
}
</script>

<style scoped>
.pf-lead {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}
.button-on-grid {
  display: grid;
  grid-template-columns: 1fr 500px 500px 1fr;
  grid-template-rows: 1fr;
  background: #ffffff;
  grid-column-gap: 50px;
  align-items: start;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
</style>

<style>
#searchResultsTutti .el-loading-spinner .path {
  stroke: #ffcc00;
}
</style>
