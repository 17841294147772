// https://vitejs.dev/config/#build-polyfillmodulepreload
// using workaround https://github.com/vitejs/vite/issues/4786
if (import.meta.env.MODE !== 'development') {
  // @ts-expect-error
  import('vite/modulepreload-polyfill')
}

import './assets/styles/index.js'
import { createGettext } from 'vue3-gettext'
import translations from './language/translation.json'
import sprintf from './plugins/sprintf.js'
import axios from 'axios'
import VueAxios from 'vue-axios'

const gettext = createGettext({
  availableLanguages: {
    de_CH: 'Deutsch',
    en_GB: 'English',
    fr_CH: 'Français',
    it_CH: 'Italiano',
  },
  defaultLanguage: 'de_CH',
  translations: translations,
})

import { createApp } from 'vue'

const modules = import.meta.globEager('./components/*.vue')
const components = {}
for (const path in modules) {
  const name = path
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')

  components[name] = modules[path].default
}

const vueMountIds = ['vue-search']

for (const mountId of vueMountIds) {
  const el = document.getElementById(mountId)
  const app = createApp({
    components,
    template: el.innerHTML,
  })
  app.use(gettext)
  app.use(VueAxios, axios)
  app.use(sprintf)
  app.mount(el)
}
