<!-- eslint-disable vue/no-v-html -->
<template>
  <span class="pf-tooltip-root">
    <span class="pf-tooltip">
      <span class="pf-tooltiptext" v-html="content" />
    </span>
  </span>
</template>

<script>
export default {
  name: 'BaseTooltip',

  props: {
    content: {
      type: String,
      default: '',
    },
  },
}
</script>

<!-- <style lang="scss" scoped>

</style> -->
