<!-- eslint-disable vue/no-v-html -->
<template>
  <button
    :class="{
      btn: showToggle,
      'btn-primary': showToggle,
      toggle: true,
      'no-show': !showToggle,
    }"
    :style="{
      margin: '2px 5px',
      paddingTop: '3px',
      paddingBottom: '3px',
      height: '32px',
    }"
    @click="$emit('toggle')"
    v-html="label"
  />
</template>

<script lang="js">
export default {
  name: 'SucheFormularToggle',

  props: {
    showToggle: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  emits: ['toggle'],
}
</script>

<style scoped>
.toggle {
  border-radius: 32px;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
}
.toggle:hover {
  color: #000000;
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
.no-show {
  border: 2px solid #cbcbcb;
  color: #cbcbcb;
  background-color: white;
}
.no-show:hover {
  background-color: #cbcbcb;
  border: none;
}
</style>
