<!-- eslint-disable vue/no-v-html -->
<template>
  <span
    id="label"
    type="text"
    :style="{ fontWeight: 700, color: 'rgb(51,51,51)' }"
    @click="orderNew"
    v-html="label"
  />
  <img
    v-if="ordered && orderDirection === 'asc'"
    class="pf-icon-small"
    :src="pfSortDown"
    alt=""
  />
  <img
    v-if="ordered && orderDirection === 'desc'"
    class="pf-icon-small"
    :src="pfSortUp"
    alt=""
  />
</template>

<script lang="ts" setup>
import pfSortDown from '../assets/pf-icons/pf-sort-down-fill-black.svg'
import pfSortUp from '../assets/pf-icons/pf-sort-up-fill-black.svg'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  ordered: {
    type: Boolean,
    required: true,
  },
  orderDirection: {
    default: 'asc',
    validator(value: 'asc' | 'desc') {
      return ['asc', 'desc'].includes(value)
    },
  },
})

const emit = defineEmits(['orderNew'])

function orderNew() {
  const order = props.orderDirection === 'asc' ? 'desc' : 'asc'
  emit('orderNew', order)
}
</script>
